import { graphql } from 'gatsby'
import React from 'react'
import { Articles } from '../components/articles'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Spacer from '../components/spacer'
import { Instagram } from '../template/perspective'

export default function MediaPage({ data: { page, allArticles, perspectiv } }) {
	return (
		<Layout footer='is-light' className={' bg--light is-dark '}>
			<SEO title='Perspectiv' />
			<div className='p40 m-p20 bg-white dark-grey'>
				<Spacer />
				<div className='text-center max-625 ma'>
					<h1 className='h1 m0 balance uppercase'>Perspectiv</h1>
					<p className='signifier white-space'>{page.title}</p>
				</div>
				<Spacer />
				<Articles articles={allArticles.nodes} featuredArticle={page.featuredArticle} />
			</div>
			<Spacer className='bg-white' />

			<div className='bg-white dark-grey'>
				<Instagram title={perspectiv.instagramTitle} handle={perspectiv.instagramHandle} gallery={perspectiv.instagramImages} />
				<Spacer />
			</div>
			<div className='pl40 pr40 m-pl20 m-pr20'>
				<div className='bb1' />
			</div>
		</Layout>
	)
}

export const query = graphql`
	query MediaPageQuery {
		page: datoCmsMediaPage {
			title
			featuredArticle {
				id
				externalLink
				shortDescription
				slug
				title
				readTime
				category {
					id
					title
				}
				image {
					gatsbyImageData
				}
				meta {
					createdAt(formatString: "DD/MM/YY")
				}
			}
		}
		perspectiv: datoCmsMediaPage { 
			instagramTitle
			instagramHandle
			instagramImages {
				gatsbyImageData
			}
		}
		allArticles: allDatoCmsArticle(sort: {fields: meta___createdAt, order: DESC}) {
			nodes {
				title
				slug
				shortDescription
				seoTitle
				seoDescription
				readTime
				id
				image {
					gatsbyImageData
				}
				externalLink
				content {
					... on DatoCmsQuote {
						id
						content
					}
					... on DatoCmsContent {
						id
						content
					}
				}
				category {
					id
					title
				}
				meta {
					createdAt(formatString: "DD/MM/YY")
				}
			}
		}
	}
`
