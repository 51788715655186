import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import Link from './link'
import { removeDuplicatesById } from './utils/arrayFns'

export const Articles = ({ featuredArticle, articles }) => {
	const [selectedCategory, setSelectedCategory] = useState('All')

	const stateProps = { selectedCategory, setSelectedCategory }

	const filteredArticles = articles.filter(article => {
		if (selectedCategory === 'All') {
			if (article.id === featuredArticle.id) return false
			return true
		}
		const articleHasCategory = article.category.find(article => article.id === selectedCategory)
		if (articleHasCategory) return true
	})

	return (
		<div>
			<Categories articles={articles} {...stateProps} />
			<div className={`mt40 grid pos-rel align-start ${selectedCategory === 'All' ? 'grid-2' : 'grid-1'} gap-30`}>
				{selectedCategory === 'All' && (
					<div className='sticky'>
						<>
							<FeaturedArticle {...featuredArticle} />
						</>
					</div>
				)}
				<div className={`grid ${selectedCategory === 'All' ? 'grid-2' : 'grid-4'} gap-30 row-gap-40`}>
					{filteredArticles.map(article => (
						<ArticleCard key={article.id} {...article} {...stateProps} />
					))}
				</div>
			</div>
		</div>
	)
}

const Categories = ({ articles, selectedCategory, setSelectedCategory }) => {
	const categories = removeDuplicatesById(articles.map(article => article.category)).flat()

	const calculateTotal = id =>
		articles.reduce((total, article) => {
			const categoryExists = article.category.find(category => category.id === id)
			if (categoryExists) return (total += 1)
			return (total += 0)
		}, 0)

	return (
		<div className='py20 bb1 overflow align-center gap-30'>
			<div className='flex gap-30 overflow-x-scroll shrink-0 w-100'>
				<button className={`category-btn ${selectedCategory === 'All' ? 'active' : ''}`} onClick={() => setSelectedCategory('All')}>
					<span>All</span>
					<sup>{articles.length}</sup>
				</button>

				{categories.map(category => (
					<button className={`category-btn ${selectedCategory === category.id ? 'active' : ''}`} key={category.id} onClick={() => setSelectedCategory(category.id)}>
						<span>{category.title}</span>
						<sup>{calculateTotal(category.id)}</sup>
					</button>
				))}
			</div>
		</div>
	)
}

const FeaturedArticle = ({ slug, title, shortDescription, readTime, category, meta, image }) => {
	return (
		<Link to={`/perspective/${slug}`} className='project--card link block-flex p40 m-p20 bg-light-grey flex-col article-card'>
			<div className='pos-rel overflow-hidden ratio-3-2 p20'>
				<span className='tag'>{category[0].title}</span>
				<GatsbyImage image={image?.gatsbyImageData} alt='' class='bg-image image--zoom' />
			</div>
			<div className='max-600'>
				<p className='small mb16 mt16'>{meta.createdAt}</p>
				<p className='h3 mt0 mb40 balance'>{title}</p>
			</div>
			<div>
				<Link to={`/perspective/${slug}`} className='read--more bb1 w-100 pb10 dark fs-small link flex align-center'>
					<span />
					Read More ({readTime})
				</Link>
			</div>
		</Link>
	)
}

const ArticleCard = ({ slug, title, readTime, category, meta, image, selectedCategory }) => {
	const categoryIndex = category.findIndex(item => item.id === selectedCategory)
	const tag = selectedCategory === 'All' ? category[0].title : category[categoryIndex].title

	return (
		<Link to={`/perspective/${slug}`} className='link project--card block-flex flex-col article-card'>
			<div className='pos-rel overflow-hidden ratio-3-2 p20'>
				<span className='tag'>{tag}</span>
				<GatsbyImage image={image?.gatsbyImageData} alt='' class='bg-image image--zoom' />
			</div>
			<div className='max-300'>
				<p className='small text-grey mb10 mt16'>{meta.createdAt}</p>
				<p className='mt0 mb40 balance'>{title}</p>
			</div>
			<div>
				<Link to={`/perspective/${slug}`} className='read--more bb1 w-100 light pb10 dark fs-small link flex align-center'>
					<span />
					Read More ({readTime})
				</Link>
			</div>
		</Link>
	)
}
