export function removeDuplicatesById(array) {
	const flattenedArray = array.flat()
	const uniqueArray = flattenedArray.reduce((accumulator, current) => {
		const index = accumulator.findIndex(item => item.id === current.id)
		if (index === -1) {
			accumulator.push(current)
		}
		return accumulator
	}, [])
	return uniqueArray
}
